import { css } from '@emotion/core';

export default css({
    width: '100%',
    background: '#f5333f',
    padding: '50px 0px',
    '& h3': {
        fontSize: 26,
        color: '#FFF',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: 50
    },
    '& > p': {
        fontSize: 18,
        lineHeight: '30px',
        color: '#FFF',
        textAlign: 'center',
        marginBottom: 50,
        padding: '0px 70px'
    },
    '& .identity-goal-cards': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    '& .goal-card': {
        width: 300,
        margin: 15,
        padding: 20,
        background: '#FFF',
        boxSizing: 'border-box',
        boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
        '& > div': {
            width: '100%',
            height: 180,
            marginBottom: 20
        },
        '& > h4': {
            fontSize: 22,
            color: '#1f300d',
            textAlign: 'center',
            marginBottom: 20
        },
        '& > p': {
            fontSize: 18,
            fontWeight: 300,
            lineHeight: '20px',
            color: '#1f300d',
            textAlign: 'center'
        }
    },
    '@media only screen and (min-width: 768px)': {
        '& h3': {
            fontSize: 28
        },
        '& > p': {
            fontSize: 22
        },
        '& .identity-goal-cards': {
            flexDirection: 'row',
            alignItems: 'stretch',
        }
    }
});

import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import Goal from '../components/identity/Goal';
import Voice from '../components/identity/Voice';
import Presentation from '../components/identity/Presentation';

const Identity = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Quiénes somos</title>
            <meta name="description" content="La Comisión de Energía del CCE está conformada por organismos, asociaciones, cámaras y empresas de la industria que trabajan por el crecimiento del sector energético y su impacto en el bienestar de los mexicanos." />
        </Helmet>
        <main style={{ overflow: 'auto ' }}>
            <Header section="identity" />
            <SubHeader title="Quiénes somos" />
            <Presentation />
            <Goal />
            <Voice />
            <Footer />
        </main>
    </>
);

export default Identity;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './styles';

const Voice = () => {
    const { riseImage, menImage, grainsImage } = useStaticQuery(graphql`
        query IdentityVoiceQuery {
            riseImage: file(relativePath: { eq: "identity/highrise.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            menImage: file(relativePath: { eq: "identity/twomen.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            grainsImage: file(relativePath: { eq: "identity/grains.jpg" }) {
                childImageSharp {
                    fluid(quality: 70, maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <section css={styles} className="identity-voice">
            <p>
                Gracias a la pluralidad y diversidad de voces de nuestros integrantes,
                la Comisión de Energía del CCE fortalece al sector energético con:
            </p>
            <div className="identity-voice-cards">
                <div className="voice-card">
                    <BackgroundImage
                        fluid={riseImage.childImageSharp.fluid}
                    />
                    <h4>Conocimientos</h4>
                    <p>
                        Construir el consenso en el sector privado.
                    </p>
                </div>
                <div className="voice-card">
                    <BackgroundImage
                        fluid={menImage.childImageSharp.fluid}
                    />
                    <h4>Capacidades</h4>
                    <p>
                        Crear una sola voz y utilizar canales de comunicación de
                        influencia adecuados con el gobierno y la sociedad.
                    </p>
                </div>
                <div className="voice-card">
                    <BackgroundImage
                        fluid={grainsImage.childImageSharp.fluid}
                    />
                    <h4>Experiencia</h4>
                    <p>
                        Enfoque social, incluyente, competitivo y sustentable, con
                        visión de largo plazo.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Voice;

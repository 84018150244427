import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './styles';

const Goal = () => {
    const { coordImage, contrImage } = useStaticQuery(graphql`
        query IdentityGoalQuery {
            coordImage: file(relativePath: { eq: "identity/windmill.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            contrImage: file(relativePath: { eq: "identity/cdmx.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <section css={styles} className="identity-goal">
            <h3>Nuestro objetivo</h3>
            <p>
                Consideramos esencial que México alcance su soberanía energética
                para el crecimiento económico y el bienestar social de los mexicanos.
            </p>
            <div className="identity-goal-cards">
                <div className="goal-card">
                    <BackgroundImage
                        fluid={coordImage.childImageSharp.fluid}
                    />
                    <h4>Coordinar</h4>
                    <p>
                        los esfuerzos de los 12 organismos del CCE,
                        asociaciones y empresas invitadas, en materia de energía.
                    </p>
                </div>
                <div className="goal-card">
                    <BackgroundImage
                        fluid={contrImage.childImageSharp.fluid}
                    />
                    <h4>Contribuir</h4>
                    <p>
                        al diseño e instrumentación de
                        políticas públicas del
                        Sector Energético, a través
                        de la colaboración con el Gobierno Federal, Poder Legislativo
                        y autoridades locales.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Goal;

import { css } from '@emotion/core';

export default css({
    width: '100%',
    '& .identity-presentation-main': {
        width: '100%',
        height: 680,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& > p': {
            fontSize: 26,
            color: '#FFF',
            lineHeight: '35px',
            textAlign: 'center',
            padding: '0px 40px',
            '&:last-of-type': {
                marginTop: 30,
            }
        }
    },
    '& .identity-presentation-blocks': {
        width: '90%',
        margin: '30px auto 20px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridColumnGap: '1em',
        gridRowGap: '1em',
        '& .idt-block': {
            '& > div': {
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(0, 0, 0, 0.4)',
            },
            '& p': {
                fontSize: 18,
                color: '#FFF',
                lineHeight: '30px',
                padding: '0px 40px',
                boxSizing: 'border-box'
            }
        },
    },
    '@media only screen and (max-width: 1023px)': {
        '& .identity-presentation-blocks': {
            '& .idt-block': {
                width: 300,
                height: 300,
                marginBottom: 10,
                justifySelf: 'center',
            }
        }
    },
    '@media only screen and (min-width: 1024px)': {
        '& .identity-presentation-main': {
            '& > p': {
                fontSize: 28,
            }
        },
        '& .identity-presentation-blocks': {
            width: '1000px',
            gridTemplateColumns: '30% 32% 33%',
            gridTemplateRows: '250px 250px 250px',
            gridColumnGap: '20px',
            gridRowGap: '20px',
            gridTemplateAreas: `
                "block1 block2 block2"
                "block3 block4 block5"
                "block6 block6 block7"
            `,
            '& .block-1': {
                gridArea: 'block1'
            },
            '& .block-2': {
                gridArea: 'block2'
            },
            '& .block-3': {
                gridArea: 'block3'
            },
            '& .block-4': {
                gridArea: 'block4'
            },
            '& .block-5': {
                gridArea: 'block5'
            },
            '& .block-6': {
                gridArea: 'block6'
            },
            '& .block-7': {
                gridArea: 'block7'
            },
        }
    }
});

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MembersSection from '../../home/MembersSection';
import styles from './styles';

const Presentation = () => {
    const data = useStaticQuery(graphql`
        query IdentityPresentationQuery {
            mainBackground: file(relativePath: { eq: "identity/slide.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 680) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            block1: file(relativePath: { eq: "identity/construction.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            block2: file(relativePath: { eq: "identity/agriculture.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            block3: file(relativePath: { eq: "identity/flag.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            block4: file(relativePath: { eq: "identity/tower.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            block5: file(relativePath: { eq: "identity/cooking.jpeg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            block6: file(relativePath: { eq: "identity/person.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            block7: file(relativePath: { eq: "identity/gears.jpg" }) {
                childImageSharp {
                    fluid(quality: 80, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <section css={styles} className="identity-presentation">
            <BackgroundImage
                className="identity-presentation-main"
                fluid={data.mainBackground.childImageSharp.fluid}
            >
                <p>
                    La Comisión de Energía del Consejo Coordinador Empresarial está
                    conformada por organismos, asociaciones, cámaras y empresas de la
                    industria que trabajan por el crecimiento del sector energético
                    y su impacto en el bienestar de los mexicanos.
                </p>
                <p>
                    Fundada en 2013.
                </p>
            </BackgroundImage>
            <MembersSection />
            <div className="identity-presentation-blocks">
                <BackgroundImage
                    className="idt-block block-1"
                    fluid={data.block1.childImageSharp.fluid}
                >
                    <div>
                        <p>
                            Somos un órgano de representación e interlocución de las empresas
                            vinculadas al sector energético.
                        </p>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="idt-block block-2"
                    fluid={data.block2.childImageSharp.fluid}
                >
                    <div>
                        <p>
                            Coordinamos las políticas y acciones de los organismos empresariales
                            y generamos soluciones y posicionamientos específicos sobre temas
                            clave de energía en el país.
                        </p>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="idt-block block-3"
                    fluid={data.block3.childImageSharp.fluid}
                >
                    <div>
                        <p>
                            Trabajamos para que México cuente con energía confiable, asequible
                            y sustentable.
                        </p>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="idt-block block-4"
                    fluid={data.block4.childImageSharp.fluid}
                >
                    <div>
                        <p>
                            Impulsamos el desarrollo de infraestructura energética con
                            impacto social.
                        </p>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="idt-block block-5"
                    fluid={data.block5.childImageSharp.fluid}
                >
                    <div>
                        <p>
                            Coadyuvamos con los objetivos de la Agenda 2030 para el
                            Desarrollo Sostenible de la ONU contra la pobreza energética.
                        </p>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="idt-block block-6"
                    fluid={data.block6.childImageSharp.fluid}
                >
                    <div>
                        <p>
                            Representamos a más de 200 empresas.
                        </p>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="idt-block block-7"
                    fluid={data.block7.childImageSharp.fluid}
                >
                    <div>
                        <p>
                            Impulsamos el crecimiento de las mujeres en posiciones ejecutivas
                            dentro de la industria de Energía.
                        </p>
                    </div>
                </BackgroundImage>
            </div>
        </section>
    );
};

export default Presentation;
